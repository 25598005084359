import "./table.scss";
import { Link, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { userRequest } from "../../requestMethods";
import { useEffect, useState } from "react";

const List = () => {
  const [tdata, setData] = useState([]);
  const location = useLocation();
  const lgaId = location.pathname.split("/")[2];

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`wards/lga/${lgaId}`);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [lgaId]);

  return (
    <>
      <div className="datatableTitle">
        Add New Ward
        <Link to={"/wards/new"} className="link">
          Add New
        </Link>
      </div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="tableCell">S/N</TableCell>
              <TableCell className="tableCell">L.G.A</TableCell>
              <TableCell className="tableCell">Ward</TableCell>
              <TableCell className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tdata.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell className="tableCell">{index + 1}</TableCell>
                <TableCell className="tableCell">{row.lgaId.name}</TableCell>
                <TableCell className="tableCell">{row.name}</TableCell>
                <TableCell className="tableCell">
                  <Link
                    to={"/wards/edit/" + row._id}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="editButton">Edit</div>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;
