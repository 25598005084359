import "./singleNews.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import List from "../../components/table/UserTable";

import { userRequest } from "../../requestMethods";

const SingleNews = () => {
  const location = useLocation();
  const newsId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await userRequest.get(`news/${newsId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [newsId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonn">
              <Link to={"/news/edit/" + newsId}>Edit</Link>
            </div>
            <h1 className="titlen">News</h1>
            {
              <div className="item">
                <img
                  src={
                    uStats.img ||
                    require("../../assets/images/sd-default-image.png")
                  }
                  alt=""
                  className="itemIm"
                />
                <div className="details">
                  <h1 className="itemTitle">{uStats.header}</h1>
                  <div className="detailItem">
                    <span className="itemKee">News:</span>
                    <span className="itemValue">{uStats.message}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Date:</span>
                    <span className="itemValue">{uStats.eventDate}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Added Date:</span>
                    <span className="itemValue">{uStats.createdAt}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Added By:</span>
                    <span className="itemValue"></span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="bottom">
          <h1 className="title">Citizens Comment</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default SingleNews;
