import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ children, redirectTo }) => {
  let admin =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.admin.isAdmin
      : false;

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const token =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.token
      : "abcd.1234";

  const decodedToken = parseJwt(token);

  // console.log(decodedToken);

  return admin && decodedToken.exp * 1000 > Date.now() ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
};

export default ProtectedRoutes;
