// const jwt = require("jsonwebtoken");
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import {
//   getStorage,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from "firebase/storage";
// import app from "../../firebase";
import {
  addUser,
  addNews,
  addMarker,
  addProject,
  addSector,
  addMda,
  addFaq,
  addBudgetFile,
  addGrantFile,
  addProjectFile,
  addWard,
  addLga,
} from "../../redux/apiCalls";
import { publicRequest, userRequest } from "../../requestMethods";

const New = ({ inputs, title }) => {
  const location = useLocation();
  const docName = location.pathname.split("/")[1];
  const [file, setFile] = useState(null);
  const [input, setInput] = useState({});
  const [img, setImg] = useState("");
  const [msg, setMsg] = useState(null);
  // const [cat, setCat] = useState([]);
  const dispatch = useDispatch();
  const nav = useNavigate();
  let fileValue;

  const useId = JSON.parse(
    JSON.parse(localStorage.getItem("persist:root")).user
  ).currentUser.admin.id;

  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSelect = (e) => {
    const newData = { ...input };
    newData[e.target.name] = e.target.value;
    setInput(newData);
    // console.log(input);
    e.target.name === "lga" && setRegion(e.target.value);
  };

  const handleCat = async (e) => {
    const budgetFile = e.target.files[0];
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("image", budgetFile);
    try {
      const {
        data: {
          image: { src },
        },
      } = await userRequest.post(`/file/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fileValue = src;
      setImg(fileValue);
      // console.log(fileValue);
    } catch (error) {
      fileValue = null;
      console.log(error);
    }
  };

  const [mda, setMda] = useState([]);
  const [lga, setLga] = useState([]);
  const [ward, setWard] = useState([]);
  const [region, setRegion] = useState("");

  useEffect(() => {
    const getMda = async () => {
      try {
        const res = await publicRequest.get("/mdas");
        setMda(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    const getLga = async () => {
      try {
        const res = await publicRequest.get("/lgas");
        setLga(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    const getWard = async () => {
      try {
        const res = await publicRequest.get(`/wards/lga/${region}`);
        setWard(res.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getMda();
    getLga();
    region !== "" && getWard();
  }, [region]);

  const handleClick = (e) => {
    e.preventDefault();
    if (file !== null) {
      // const fileName = new Date().getTime() + file.name;
      const lgaName = lga.filter((m) => {
        return m._id === input.lga;
      });
      const mdaName = mda.filter((m) => {
        return m._id === input.mda;
      });
      let user;

      if (docName === "project") {
        user = {
          ...input,
          lga: lgaName[0].name,
          mda: mdaName[0].name,
          sector: mdaName[0].sectorId.name,
          img: img,
          userId: useId,
        };
      } else {
        user = {
          ...input,
          img: img,
          userId: useId,
        };
      }
      // console.log(user);

      docName === "admins"
        ? addUser(user, dispatch)
            .then(() => {
              nav("/admins");
              setMsg("Admin user added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "marker"
        ? addMarker(user, dispatch)
            .then(() => {
              nav("/marker");
              setMsg("Map marker added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "project"
        ? addProject(user, dispatch)
            .then(() => {
              nav("/project");
              setMsg("Project added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "mdas"
        ? addMda(user, dispatch)
            .then(() => {
              nav("/mdas");
              setMsg("MDA added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "faqs"
        ? addFaq(user, dispatch)
            .then(() => {
              nav("/faqs");
              setMsg("FAQ added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "lgas"
        ? addLga(user, dispatch)
            .then(() => {
              nav("/lgas");
              setMsg("L.G.A added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "wards"
        ? addWard(user, dispatch)
            .then(() => {
              nav("/wards");
              setMsg("ward added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "budgetFile"
        ? addBudgetFile(user, dispatch)
            .then(() => {
              nav("/budgetFile");
              setMsg("Budget year added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "grant"
        ? addGrantFile(user, dispatch)
            .then(() => {
              nav("/grant");
              setMsg("Grant year added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "partnersProject"
        ? addProjectFile(user, dispatch)
            .then(() => {
              nav("/partnersProject");
              setMsg("Partner added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "sector"
        ? addSector(user, dispatch)
            .then(() => {
              nav("/sector");
              setMsg("Sector added");
            })
            .catch((error) => {
              console.log(error);
            })
        : addNews(user, dispatch)
            .then(() => {
              nav("/news");
              setMsg("News added");
            })
            .catch((error) => {
              console.log(error);
            });
    } else {
      const lgaName = lga.filter((m) => {
        return m._id === input.lga;
      });
      const mdaName = mda.filter((m) => {
        return m._id === input.mda;
      });

      let user;

      docName === "project"
        ? (user = {
            ...input,
            lga: lgaName[0].name,
            mda: mdaName[0].name,
            sector: mdaName[0].sectorId.name,
            userId: useId,
            img: "https://api.admehub.ng/uploads/sd-default-image.png",
          })
        : (user = {
            ...input,
            userId: useId,
          });

      docName === "admins"
        ? addUser(user, dispatch)
            .then(() => {
              nav("/admins");
              setMsg("Admin user added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "marker"
        ? addMarker(user, dispatch)
            .then(() => {
              nav("/marker");
              setMsg("Map marker added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "project"
        ? addProject(user, dispatch)
            .then(() => {
              nav("/project");
              setMsg("Project added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "mdas"
        ? addMda(user, dispatch)
            .then(() => {
              nav("/mdas");
              setMsg("MDA added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "faqs"
        ? addFaq(user, dispatch)
            .then(() => {
              nav("/faqs");
              setMsg("FAQ added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "lgas"
        ? addLga(user, dispatch)
            .then(() => {
              nav("/lgas");
              setMsg("L.G.A added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "wards"
        ? addWard(user, dispatch)
            .then(() => {
              nav("/wards");
              setMsg("ward added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "budgetFile"
        ? addBudgetFile(user, dispatch)
            .then(() => {
              nav("/budgetFile");
              setMsg("Budget year added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "grant"
        ? addGrantFile(user, dispatch)
            .then(() => {
              nav("/grant");
              setMsg("Grant year added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "partnersProject"
        ? addProjectFile(user, dispatch)
            .then(() => {
              nav("/partnersProject");
              setMsg("Partner added");
            })
            .catch((error) => {
              console.log(error);
            })
        : docName === "sector"
        ? addSector(user, dispatch)
            .then(() => {
              nav("/sector");
              setMsg("Sector added");
            })
            .catch((error) => {
              console.log(error);
            })
        : addNews(user, dispatch)
            .then(() => {
              nav("/news");
              setMsg("News added");
            })
            .catch((error) => {
              console.log(error);
            });
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
          <p className="msg">{msg !== null && msg}</p>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : require("../../assets/images/no-image-icon-0.jpg")
              }
              alt=""
            />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={handleCat}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === "textarea" ? (
                    <textarea
                      rows="5"
                      name={input.field}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    ></textarea>
                  ) : input.type === "select" ? (
                    <select name={input.field} onChange={handleSelect}>
                      <option value="">{input.field}</option>
                      {input.field === "mda"
                        ? mda.map((md, i) => (
                            <option value={md._id} key={i}>
                              {md.name}
                            </option>
                          ))
                        : input.field === "lga"
                        ? lga.map((lg, i) => (
                            <option value={lg._id} key={i}>
                              {lg.name}
                            </option>
                          ))
                        : ward.map((wd, i) => (
                            <option value={wd.name} key={i}>
                              {wd.name}
                            </option>
                          ))}
                    </select>
                  ) : (
                    <input
                      type={input.type}
                      name={input.field}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ))}
              <div className="submit-btn">
                <button onClick={handleClick}>Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
