export const userInputs = [
  {
    id: 1,
    label: "Username",
    field: "username",
    type: "text",
    placeholder: "john_doe",
  },
  {
    id: 2,
    label: "Name and surname",
    field: "name",
    type: "text",
    placeholder: "John Doe",
  },
  {
    id: 3,
    label: "Email",
    field: "email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },
  {
    id: 4,
    label: "Password",
    field: "password",
    type: "password",
  },
  {
    id: 6,
    label: "M.D.A",
    field: "mda",
    type: "text",
    placeholder: "M.D.A",
  },
  {
    id: 7,
    label: "Department",
    field: "department",
    type: "text",
    placeholder: "ICT",
  },
];

export const productInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Size",
    type: "text",
    placeholder: "23, M",
  },
  {
    id: 6,
    label: "Color",
    type: "text",
    placeholder: "Black",
  },
  {
    id: 7,
    label: "Stock",
    type: "text",
    placeholder: "Quantity",
  },
];

export const newsInputs = [
  {
    id: 1,
    label: "Header",
    field: "header",
    type: "text",
    placeholder: "News and Events Title",
  },
  {
    id: 2,
    label: "Date",
    field: "eventDate",
    type: "date",
    placeholder: "Date",
  },
  {
    id: 3,
    label: "News",
    field: "message",
    type: "textarea",
    placeholder: "Message",
  },
];

export const sectorInputs = [
  {
    id: 1,
    label: "Name",
    field: "name",
    type: "text",
    placeholder: "Name",
  },
];

export const mdaInputs = [
  {
    id: 1,
    label: "Sector",
    field: "sectorId",
    type: "text",
    placeholder: "Sector",
  },
  {
    id: 2,
    label: "Code",
    field: "code",
    type: "text",
    placeholder: "Code",
  },
  {
    id: 3,
    label: "Name",
    field: "name",
    type: "text",
    placeholder: "Name",
  },
];

export const lgaInputs = [
  {
    id: 1,
    label: "L.G.A Name",
    field: "name",
    type: "text",
    placeholder: "L.G.A Name",
  },
];

export const wardInputs = [
  {
    id: 1,
    label: "L.G.A",
    field: "lgaId",
    type: "text",
    placeholder: "L.G.A",
  },
  {
    id: 2,
    label: "Ward Name",
    field: "name",
    type: "text",
    placeholder: "Ward Name",
  },
];

export const fiscalInputs = [
  {
    id: 1,
    label: "Fiscal Year",
    field: "fiscal",
    type: "text",
    placeholder: "Fiscal Year",
  },
];
export const partnerInputs = [
  {
    id: 1,
    label: "Partner's Name",
    field: "partner",
    type: "text",
    placeholder: "Partner's Name",
  },
  {
    id: 2,
    label: "Partner's Base",
    field: "partnerType",
    type: "select",
    placeholder: "Partner's Base",
  },
];

export const fileInputs = [
  {
    id: 1,
    label: "File Title",
    field: "title",
    type: "text",
    placeholder: "File Title",
  },
  {
    id: 2,
    label: "File Category",
    field: "fileType",
    type: "select",
    placeholder: "File Category",
  },
];

export const grantFileInputs = [
  {
    id: 1,
    label: "File Title",
    field: "title",
    type: "text",
    placeholder: "File Title",
  },
  {
    id: 2,
    label: "File Category",
    field: "fileType",
    type: "select",
    placeholder: "File Category",
  },
];

export const faqInputs = [
  {
    id: 1,
    label: "Question",
    field: "question",
    type: "text",
    placeholder: "Frequently asked questions",
  },
  {
    id: 2,
    label: "Answer",
    field: "answer",
    type: "text",
    placeholder: "Answer",
  },
];

export const budgetInputs = [
  {
    id: 1,
    label: "M.D.A",
    field: "mdaId",
    type: "text",
    placeholder: "M.D.A",
  },
  {
    id: 2,
    label: "Year",
    field: "year",
    type: "text",
    placeholder: "Budget Year",
  },
];

export const markersInputs = [
  {
    id: 1,
    label: "Longitude",
    field: "longitude",
    type: "text",
    placeholder: "Longitude",
  },
  {
    id: 2,
    label: "Latitude",
    field: "latitude",
    type: "text",
    placeholder: "Latitude",
  },
  {
    id: 3,
    label: "Notification",
    field: "notification",
    type: "text",
    placeholder: "Notification",
  },
  {
    id: 4,
    label: "Information",
    field: "information",
    type: "textarea",
    placeholder: "Information",
  },
];

export const projectInputs = [
  {
    id: 1,
    label: "Title",
    field: "title",
    type: "text",
    placeholder: "Title",
  },
  {
    id: 2,
    label: "Category",
    field: "category",
    type: "text",
    placeholder: "Category",
  },
  {
    id: 3,
    label: "Kilometres/Number",
    field: "kilometres",
    type: "text",
    placeholder: "kilometres/Number",
  },
  {
    id: 4,
    label: "M.D.A",
    field: "mda",
    type: "select",
    placeholder: "M.D.A",
  },
  {
    id: 5,
    label: "L.G.A",
    field: "lga",
    type: "select",
    placeholder: "L.G.A",
  },
  {
    id: 6,
    label: "Ward",
    field: "ward",
    type: "select",
    placeholder: "Ward",
  },
  {
    id: 7,
    label: "Contractor",
    field: "contractor",
    type: "text",
    placeholder: "Contractor",
  },
  {
    id: 8,
    label: "Cost",
    field: "cost",
    type: "text",
    placeholder: "Cost",
  },
  {
    id: 9,
    label: "Longitude",
    field: "longitude",
    type: "text",
    placeholder: "Longitude",
  },
  {
    id: 10,
    label: "Latitude",
    field: "latitude",
    type: "text",
    placeholder: "Latitude",
  },
  {
    id: 11,
    label: "Progress",
    field: "progress",
    type: "text",
    placeholder: "Progress",
  },
  {
    id: 12,
    label: "Status",
    field: "status",
    type: "text",
    placeholder: "Status",
  },
  {
    id: 13,
    label: "Project Year",
    field: "projectYear",
    type: "text",
    placeholder: "Project Year",
  },
  {
    id: 14,
    label: "Date",
    field: "eventDate",
    type: "date",
    placeholder: "Date",
  },
  {
    id: 15,
    label: "About",
    field: "message",
    type: "textarea",
    placeholder: "About",
  },
];
