import "./table.scss";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { userRequest } from "../../requestMethods";
import { useEffect, useState, useRef } from "react";

const List = () => {
  const [tdata, setData] = useState([]);
  const [prev, setPrev] = useState([]);
  const [tinput, setTinput] = useState([]);
  // const [bal, setBal] = useState(null);
  //   const [pageSize, setPageSize] = useState(20);
  const location = useLocation();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const ref = useRef(null);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(
          `budget/report/${location.state.pk}/${location.state.year}`
        );
        setData(res.data.reverse());
        const res1 = await userRequest.get(
          `budgetPerformance/report/6374384b0b885b2bb07c5c6b`
        );
        // res1.data && console.log(res1.data);
        setPrev(res1.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [location.state.pk, location.state.year]);

  const inputChange = (e) => {
    setTinput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    // const el2 = ref.current;
    // console.log(el2);
  };

  const handleSubmit = () => {
    // console.log(tinput);
    Object.keys(tinput).map((key, index) => {
      const kId = key.split("_");
      const sbtValue = {
        userId: "635fc088723a8407b7cb0da6",
        budgetId: kId[2],
        month: location.state.month,
        amount: tinput[key],
      };
      // console.log(sbtValue);
      return userRequest
        .post("budgetPerformance", sbtValue)
        .then((res) => {
          console.log("submit successful");
        })
        .catch((err) => {
          console.log(err);
        });
    });
    // tinput.map((frmInput) => {
    //   const frms = frmInput.split("_");
    //   console.log(frms);
    // });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginVertical: "1rem",
        }}
      >
        <h1>{location.state.mda}</h1>
        <h3>Monthly Returns: Capital Expenditure</h3>
        <p>
          Reporting Month:{" "}
          {months[location.state.month - 1] + " " + location.state.year}
        </p>
      </div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="table-header">
            <TableRow>
              {/* <TableCell className="tableCell">Tracking ID</TableCell> */}

              <TableCell className="tableCell">
                Programme Code & Description
              </TableCell>
              <TableCell className="tableCell">Description</TableCell>
              <TableCell className="tableCell">Approved Budget</TableCell>
              <TableCell className="tableCell">Previous Months</TableCell>
              <TableCell className="tableCell">Current Month</TableCell>
              <TableCell className="tableCell">Total to Date</TableCell>
              <TableCell className="tableCell">Performance</TableCell>
              <TableCell className="tableCell">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tdata.map((row) => (
              <TableRow key={row._id}>
                {/* <TableCell className="tableCell">{row._id}</TableCell> */}
                {/* <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.img} alt="" className="image" />
                  {row.product}
                </div>
              </TableCell> */}
                <TableCell className="tableCell">{row.programme}</TableCell>
                <TableCell className="tableCell">{row.description}</TableCell>
                <TableCell className="tableCell">
                  {row.amount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
                <TableCell className="tableCell">
                  {prev
                    .filter((person) => person._id === row._id)
                    .map((filteredPerson) =>
                      filteredPerson.totalAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    )}
                </TableCell>
                <TableCell className="tableCell">
                  {row.amount > 0 && (
                    <input
                      style={{
                        width: "80%",
                        padding: 10,
                        outline: "none",
                        border: "1px solid #ccc",
                        borderRadius: 5,
                      }}
                      type="text"
                      name={"amount_ret_" + row._id}
                      id={"amount_ret_" + row._id}
                      onInput={(e) => inputChange(e)}
                    />
                  )}
                </TableCell>
                <TableCell className="tableCell">
                  {prev
                    .filter((person) => person._id === row._id)
                    .map((filteredPerson) =>
                      filteredPerson.totalAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    )}
                </TableCell>
                <TableCell className="tableCell">
                  {prev
                    .filter((person) => person._id === row._id)
                    .map(
                      (filteredPerson) =>
                        (
                          (filteredPerson.totalAmount / row.amount) *
                          100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + "%"
                    )}
                </TableCell>
                <TableCell className={"tableCell"} ref={ref}>
                  {prev
                    .filter((person) => person._id === row._id)
                    .map((filteredPerson) =>
                      (row.amount - filteredPerson.totalAmount).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    )}
                </TableCell>
                {/* <TableCell className="tableCell">
                  <Link
                    to={"/report/" + row._id}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="viewButton">View</div>
                  </Link>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            padding: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              padding: 15,
              marginVertical: 10,
              color: "#fff",
              backgroundColor: "#0e9e69",
              border: 0,
              cursor: "pointer",
              borderRadius: 2,
            }}
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </TableContainer>
    </>
  );
};

export default List;
