import "./singleAccount.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/UserTable";
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest, INDEX_URL } from "../../requestMethods";

const Single = () => {
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`admins/${userId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [userId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={"/admins/edit/" + userId}>Edit</Link>
            </div>
            <h1 className="titlen">Account Information</h1>
            {
              <div className="item">
                <img
                  src={
                    `${INDEX_URL}/${uStats.img}` ||
                    require("../../assets/images/staff.png")
                  }
                  alt=""
                  className="itemImgn"
                />
                <div className="details">
                  <h1 className="itemTitle">{uStats.name}</h1>
                  <div className="detailItem">
                    <span className="itemKeyu">Email:</span>
                    <span className="itemValue">{uStats.email}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Phone:</span>
                    <span className="itemValue">{uStats.phone}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">M.D.A:</span>
                    <span className="itemValue">{uStats.mda}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Department:</span>
                    <span className="itemValue">{uStats.department}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          {/* <div className="right">
            <Chart
              aspect={3 / 1}
              title="User Report activity ( Last 6 Months)"
            />
          </div> */}
        </div>
        <div className="bottom">
          <h1 className="title">Last Report</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default Single;
