import "./singleReport.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

const SingleReport = () => {
  const location = useLocation();
  const reportId = location.pathname.split("/")[2];
  const [tdata, setTdata] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await userRequest.get(`report/${reportId}`);
        setTdata(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [reportId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">Edit</div>
            <h1 className="titlen">Report</h1>
            {
              <div className="item">
                <div className="details">
                  {/* <h1 className="itemTitle">{uStats.header}</h1> */}
                  <div className="detailItem">
                    <span className="itemKeyu">Name:</span>
                    <span className="itemValue"></span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Project:</span>
                    <span className="itemValue"></span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Project Status:</span>
                    <span className="itemValue">{tdata.status}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Perfomance:</span>
                    <span className="itemValue">{tdata.performance}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Comment:</span>
                    <span className="itemValue">{tdata.comment}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Added Date:</span>
                    <span className="itemValue">{tdata.createdAt}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleReport;
