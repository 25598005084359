import "./singleProject.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import ReportList from "../../components/table/userReport";
import List from "../../components/table/UserTable";

const SingleProject = () => {
  const location = useLocation();
  const projectId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);
  const [showNav, setShowNav] = useState("reports");

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await userRequest.get(`project/${projectId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [projectId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={"/project/edit/" + projectId}>Edit</Link>
            </div>
            <h1 className="titlen">Project</h1>
            {
              <div className="projectItem">
                <div>
                  <img
                    src={uStats.img || require("../../assets/images/staff.png")}
                    alt=""
                    className="itemImgn"
                  />
                </div>
                <div className="details">
                  <h1 className="itemTitle">{uStats.header}</h1>
                  <div className="detailItem">
                    <span className="itemKeyp">Title:</span>
                    <span className="itemValue">{uStats.title}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Category:</span>
                    <span className="itemValue">{uStats.category}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">kilometres/Number:</span>
                    <span className="itemValue">{uStats.kilometres}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Sector:</span>
                    <span className="itemValue">{uStats.sector}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">M.D.A:</span>
                    <span className="itemValue">{uStats.mda}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">L.G.A:</span>
                    <span className="itemValue">{uStats.lga}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Ward:</span>
                    <span className="itemValue">{uStats.ward}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Contractor:</span>
                    <span className="itemValue">{uStats.contractor}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Cost:</span>
                    <span className="itemValue">{uStats.cost}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Longitude:</span>
                    <span className="itemValue">{uStats.longitude}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Latitude:</span>
                    <span className="itemValue">{uStats.latitude}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Status:</span>
                    <span className="itemValue">{uStats.status}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Progress:</span>
                    <span className="itemValue">{uStats.progress}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">About:</span>
                    <span className="itemValue">{uStats.message}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Project Year:</span>
                    <span className="itemValue">{uStats.projectYear}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Date:</span>
                    <span className="itemValue">{uStats.eventDate}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyp">Added Date:</span>
                    <span className="itemValue">{uStats.createdAt}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="bottom">
          <div className="btnDiv">
            <button
              onClick={() => setShowNav("reports")}
              className={showNav === "reports" ? "slct" : "idl"}
            >
              Reports
            </button>
            <button onClick={() => setShowNav("comments")}>Comments</button>
          </div>
          <h1 className="title">LATEST {showNav.toLocaleUpperCase()}</h1>
          {showNav === "reports" ? <ReportList /> : <List />}
        </div>
      </div>
    </div>
  );
};

export default SingleProject;
