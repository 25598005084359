import "./table.scss";
import { Link, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { userRequest } from "../../requestMethods";
import { useEffect, useState } from "react";

const List = () => {
  const [tdata, setData] = useState([]);
  const location = useLocation();
  const fileId = location.pathname.split("/")[2];

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`grant/${fileId}`);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [fileId]);

  return (
    <>
      <div className="datatableTitle">
        Add New File
        <Link to={`/grant/file/${fileId}`} className="link">
          Add New
        </Link>
      </div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="table-header">
            <TableRow>
              <TableCell className="tableCell">S/N</TableCell>
              <TableCell className="tableCell">Title</TableCell>
              <TableCell className="tableCell">Type</TableCell>
              <TableCell className="tableCell">File</TableCell>
              <TableCell className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tdata.docs &&
              tdata.docs.map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell className="tableCell">{index + 1}</TableCell>
                  <TableCell className="tableCell">{row.title}</TableCell>
                  <TableCell className="tableCell">
                    {/* {row.fileType === 0 ? "Citizens" : "State"} Budget */}
                    {row.fileType === 0
                      ? "Citizens"
                      : row.fileType === 1
                      ? "State"
                      : row.fileType === 2
                      ? "Performance"
                      : row.fileType === 3
                      ? "Mtef"
                      : "Unknown"}
                  </TableCell>
                  <TableCell className="tableCell">{row.url}</TableCell>
                  <TableCell className="tableCell">
                    <Link
                      to={"/grant/edit/" + row._id}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="editButton">Edit</div>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default List;
