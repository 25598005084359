import "./singleSector.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

const SingleSector = () => {
  const location = useLocation();
  const sectorId = location.pathname.split("/")[2];
  const [tdata, setTdata] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await userRequest.get(`sector/${sectorId}`);
        setTdata(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [sectorId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={"/sector/edit/" + sectorId}>Edit</Link>
            </div>
            <h1 className="titlen">Sectors</h1>
            {
              <div className="item">
                <div className="details">
                  {/* <h1 className="itemTitle">{tdata.header}</h1> */}
                  <div className="detailItem">
                    <span className="itemKeyu">Sector:</span>
                    <span className="itemValue">{tdata.name}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Added Date:</span>
                    <span className="itemValue">{tdata.createdAt}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleSector;
