import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useDispatch } from "react-redux";
import { login } from "../../redux/apiCalls";

import "./login.scss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { dispatch } = useContext(DarkModeContext);
  const reduxDispatch = useDispatch();
  const nav = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    login(reduxDispatch, { email, password })
      .then(() => {
        nav("/dashboard");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <div className="item">
        <DarkModeOutlinedIcon
          className="icon"
          onClick={() => dispatch({ type: "TOGGLE" })}
        />
      </div>
      <div className="signin">
        {/* <span className="logo">Adamawa State</span>
        <h2>Monitoring & Evaluation </h2> */}
        <div className="logoContainer">
          <img
            className="logo"
            alt=""
            src={require("../../assets/images/logo.png")}
          />
        </div>
        <h2>Sign In</h2>
        <form>
          <div className="formInput">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              value={email}
              placeholder="johndoe@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="formInput">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button onClick={handleClick}>Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
