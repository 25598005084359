import "./list.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import ReportTable from "../../components/table/ReportTable";

const ReportList = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <ReportTable />
      </div>
    </div>
  );
};

export default ReportList;
