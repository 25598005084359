import "./singleContact.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

const SingleContact = () => {
  const location = useLocation();
  const reportId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await userRequest.get(`contact/${reportId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [reportId]);

  //  useEffect(() => {
  //    const getStats = async () => {
  //      try {
  //        const res = await userRequest.get("orders/income?pid=" + productId);
  //        const list = res.data.sort((a, b) => {
  //          return a._id - b._id;
  //        });
  //        list.map((item) =>
  //          setPStats((prev) => [
  //            ...prev,
  //            { name: MONTHS[item._id - 1], sales: item.total },
  //          ])
  //        );
  //      } catch (error) {
  //        console.log(error);
  //      }
  //    };
  //    getStats();
  //  }, [productId, MONTHS]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Report</h1>
            {
              <div className="item">
                <img
                  src={require("../../assets/images/staff.png")}
                  alt=""
                  className="itemImg"
                />
                <div className="details">
                  <h1 className="itemTitle">{uStats.header}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Name:</span>
                    <span className="itemValue">{uStats.name}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Phone Number:</span>
                    <span className="itemValue">{uStats.phone}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Email:</span>
                    <span className="itemValue">{uStats.email}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Message:</span>
                    <span className="itemValue">{uStats.message}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Added Date:</span>
                    <span className="itemValue">{uStats.createdAt}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div> */}
        </div>
        {/* <div className="bottom">
          <h1 className="title">Last Transactions</h1>
          <List />
        </div> */}
      </div>
    </div>
  );
};

export default SingleContact;
