import "./table.scss";
import { Link, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { userRequest } from "../../requestMethods";
import { useEffect, useState } from "react";

const List = () => {
  const location = useLocation();
  const budgetId = location.pathname.split("/")[2];
  const [orders, setOrders] = useState([]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const getReports = async () => {
      try {
        const res = await userRequest.get(
          `budgetPerformance/performance/${budgetId}`
        );
        setOrders(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getReports();
  }, [budgetId]);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            {/* <TableCell className="tableCell">Tracking ID</TableCell> */}
            <TableCell className="tableCell">Month</TableCell>
            <TableCell className="tableCell">Amount</TableCell>
            <TableCell className="tableCell">Added Date</TableCell>
            <TableCell className="tableCell">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length > 0 &&
            orders
              .reverse()
              .slice(0, 10)
              .map((row) => (
                <TableRow key={row._id}>
                  {/* <TableCell className="tableCell">{row._id}</TableCell> */}
                  {/* <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.img} alt="" className="image" />
                  {row.product}
                </div>
              </TableCell> */}
                  <TableCell className="tableCell">
                    {months[row.month - 1]}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.amount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell className="tableCell">{row.createdAt}</TableCell>
                  {/* <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell> */}
                  <TableCell className="tableCell cellAction">
                    <Link
                      to={"/newsComment/" + row._id}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="viewButton">View</div>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
