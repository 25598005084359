import "./table.scss";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { userRequest } from "../../requestMethods";
import { useEffect, useState } from "react";

const List = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const getReports = async () => {
      try {
        const res = await userRequest.get("report");
        setOrders(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getReports();
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            {/* <TableCell className="tableCell">Tracking ID</TableCell> */}
            <TableCell className="tableCell">Name</TableCell>
            <TableCell className="tableCell">Project</TableCell>
            <TableCell className="tableCell">Project Status</TableCell>
            <TableCell className="tableCell">Performance</TableCell>
            <TableCell className="tableCell">Date Added</TableCell>
            <TableCell className="tableCell">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders
            .reverse()
            .slice(0, 10)
            .map((row) => (
              <TableRow key={row._id}>
                {/* <TableCell className="tableCell">{row._id}</TableCell> */}
                {/* <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.img} alt="" className="image" />
                  {row.product}
                </div>
              </TableCell> */}
                <TableCell className="tableCell">{row.userId.name}</TableCell>
                <TableCell className="tableCell">
                  {row.projectId.title}
                </TableCell>
                <TableCell className="tableCell">{row.status}</TableCell>
                <TableCell className="tableCell">{row.performance}</TableCell>
                {/* <TableCell className="tableCell">
                  {row.comment.substring(0, 50)}
                </TableCell> */}
                <TableCell className="tableCell">{row.createdAt}</TableCell>
                {/* <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell> */}
                <TableCell className="tableCell cellAction">
                  <Link
                    to={"/report/" + row._id}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="viewButton">View</div>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
