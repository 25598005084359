import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const Featured = () => {
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Budget</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar
            className="rd"
            value={40}
            text={"2022"}
            strokeWidth={5}
            styles={buildStyles({
              textColor: "red",
              trailColor: "#0e9e69",
              pathColor: "#9e1d00",
            })}
          />
        </div>
        <p className="title">
          <span className="cad">
            <span className="indicator">&nbsp;</span>Recurrent 60%
          </span>
          <span className="cad">
            <span className="indicator1">&nbsp;</span>Capital 40%
          </span>
        </p>
        <p className="amount">₦163,629,910,040.00</p>
        <p className="desc">
          Previous transactions processing. Last update may not be included.
        </p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Personnel</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small" />
              <div className="resultAmount"> ₦37,828,118,000.00</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Overhead</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount"> ₦31,447,250,024.00</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Social Benefits</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount"> ₦29,579,875,016.00</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle"> Capital Expenditure</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount"> ₦72,817,892,910.00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
