import "./singleSuggestion.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

const SingleSuggestion = () => {
  const location = useLocation();
  const suggestionId = location.pathname.split("/")[2];
  const [tdata, setTdata] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await userRequest.get(`suggestion/${suggestionId}`);
        setTdata(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [suggestionId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">Edit</div>
            <h1 className="titlen">Suggestion</h1>
            {
              <div className="item">
                <img
                  src={tdata.img || require("../../assets/images/staff.png")}
                  alt=""
                  className="itemImgn"
                />
                <div className="details">
                  {/* <h1 className="itemTitle">{tdata.header}</h1> */}
                  <div className="detailItem">
                    <span className="itemKeyu">Name:</span>
                    <span className="itemValue">{}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Sector:</span>
                    <span className="itemValue">{tdata.sector}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">L.G.A:</span>
                    <span className="itemValue">
                      {tdata.lga && tdata.lga.name}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Title:</span>
                    <span className="itemValue">{tdata.title}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Comment:</span>
                    <span className="itemValue">{tdata.comment}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Added Date:</span>
                    <span className="itemValue">{tdata.createdAt}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleSuggestion;
