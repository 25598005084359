import {
  addNewsStart,
  addNewsSuccess,
  addNewsFailure,
  addMarkerStart,
  addMarkerSuccess,
  addMarkerFailure,
  addProjectStart,
  addProjectSuccess,
  addProjectFailure,
  addSectorStart,
  addSectorSuccess,
  addSectorFailure,
  addMdaStart,
  addMdaSuccess,
  addMdaFailure,
  addLgaStart,
  addLgaSuccess,
  addLgaFailure,
  addWardStart,
  addWardSuccess,
  addWardFailure,
  addFaqStart,
  addFaqSuccess,
  addFaqFailure,
  addFiscalStart,
  addFiscalSuccess,
  addFiscalFailure,
  addPeriodStart,
  addPeriodSuccess,
  addPeriodFailure,
  addPartnerStart,
  addPartnerSuccess,
  addPartnerFailure,
} from "./productRedux";
import {
  getUserFailure,
  getUserStart,
  getUserSuccess,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  addUserStart,
  addUserSuccess,
  addUserFailure,
  loginStart,
  loginFailure,
  loginSuccess,
  logoutStart,
} from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/adminAuth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (error) {
    dispatch(loginFailure());
  }
};

export const logout = async (dispatch) => {
  dispatch(logoutStart());
};

export const getUsers = async (dispatch) => {
  dispatch(getUserStart());
  try {
    const res = await userRequest.get("/users");
    dispatch(getUserSuccess(res.data));
  } catch (error) {
    dispatch(getUserFailure());
  }
};

export const deleteUser = async (id, dispatch) => {
  dispatch(deleteUserStart());
  try {
    await userRequest.delete(`/users/${id}`);
    dispatch(deleteUserSuccess(id));
  } catch (error) {
    dispatch(deleteUserFailure());
  }
};

export const updateUser = async (id, user, dispatch) => {
  dispatch(updateUserStart());
  try {
    await userRequest.patch(`/users/${id}`);
    dispatch(updateUserSuccess({ id, user }));
  } catch (error) {
    dispatch(updateUserFailure());
  }
};

export const addUser = async (user, dispatch) => {
  dispatch(addUserStart());
  try {
    const res = await userRequest.post(`/adminAuth/register`, user);
    dispatch(addUserSuccess(res.data));
  } catch (error) {
    dispatch(addUserFailure());
  }
};

export const addNews = async (news, dispatch) => {
  dispatch(addNewsStart());
  try {
    const res = await userRequest.post(`/news`, news);
    dispatch(addNewsSuccess(res.data));
  } catch (error) {
    dispatch(addNewsFailure());
  }
};

export const addMarker = async (marker, dispatch) => {
  dispatch(addMarkerStart());
  try {
    const res = await userRequest.post(`/marker`, marker);
    dispatch(addMarkerSuccess(res.data));
  } catch (error) {
    dispatch(addMarkerFailure());
  }
};

export const addSector = async (sector, dispatch) => {
  dispatch(addSectorStart());
  try {
    const res = await userRequest.post(`/sector`, sector);
    dispatch(addSectorSuccess(res.data));
  } catch (error) {
    dispatch(addSectorFailure());
  }
};

export const addProject = async (project, dispatch) => {
  dispatch(addProjectStart());
  try {
    const res = await userRequest.post(`/project`, project);
    dispatch(addProjectSuccess(res.data));
  } catch (error) {
    dispatch(addProjectFailure());
  }
};

export const addMda = async (mda, dispatch) => {
  dispatch(addMdaStart());
  try {
    const res = await userRequest.post(`/mdas`, mda);
    dispatch(addMdaSuccess(res.data));
  } catch (error) {
    dispatch(addMdaFailure());
  }
};

export const addLga = async (lga, dispatch) => {
  dispatch(addLgaStart());
  try {
    const res = await userRequest.post(`/lgas`, lga);
    dispatch(addLgaSuccess(res.data));
  } catch (error) {
    dispatch(addLgaFailure());
  }
};

export const addWard = async (ward, dispatch) => {
  dispatch(addWardStart());
  try {
    const res = await userRequest.post(`/wards`, ward);
    dispatch(addWardSuccess(res.data));
  } catch (error) {
    dispatch(addWardFailure());
  }
};

export const addFaq = async (faq, dispatch) => {
  dispatch(addFaqStart());
  try {
    const res = await userRequest.post(`/faqs`, faq);
    dispatch(addFaqSuccess(res.data));
  } catch (error) {
    dispatch(addFaqFailure());
  }
};

export const addBudgetFile = async (fiscal, dispatch) => {
  dispatch(addFiscalStart());
  try {
    const res = await userRequest.post(`/budgetFile`, fiscal);
    dispatch(addFiscalSuccess(res.data));
  } catch (error) {
    dispatch(addFiscalFailure());
  }
};

export const addGrantFile = async (period, dispatch) => {
  dispatch(addPeriodStart());
  try {
    const res = await userRequest.post(`/grant`, period);
    dispatch(addPeriodSuccess(res.data));
  } catch (error) {
    dispatch(addPeriodFailure());
  }
};

export const addProjectFile = async (partner, dispatch) => {
  dispatch(addPartnerStart());
  try {
    const res = await userRequest.post(`/partnersProject`, partner);
    dispatch(addPartnerSuccess(res.data));
  } catch (error) {
    dispatch(addPartnerFailure());
  }
};
