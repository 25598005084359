import React from "react";
import "./error.scss";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const nav = useNavigate();
  return (
    <div className="container">
      <h1>Oops!</h1>
      <h2>404-Page not found.</h2>
      <p>
        The page you are looking for might have been removed or replaced or is
        temporarily unavailable.
      </p>
      <button
        onClick={() =>
          window.history.state.idx && window.history.state.idx > 0
            ? nav(-1)
            : nav("/")
        }
        className="link"
      >
        Back to Homepage
      </button>
    </div>
  );
};

export default Error;
