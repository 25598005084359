import "./singleLga.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import List from "../../components/table/WardTable";

const SingleLga = () => {
  const location = useLocation();
  const lgaId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getLgas = async () => {
      try {
        const res = await userRequest.get(`lgas/${lgaId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLgas();
  }, [lgaId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={"/lgas/edit/" + lgaId}>Edit</Link>
            </div>
            <h1 className="titlen">L.G.A</h1>
            {
              <div className="item">
                {/* <img
                  src={uStats.img || require("../../assets/images/staff.png")}
                  alt=""
                  className="itemImgn"
                /> */}
                <div className="details">
                  <div className="detailItem">
                    <span className="itemKeyu">L.G.A:</span>
                    <span className="itemValue">{uStats.name}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="bottom">
          <List />
        </div>
      </div>
    </div>
  );
};

export default SingleLga;
