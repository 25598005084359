import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import List from "../../components/table/UserTable";
import { userRequest } from "../../requestMethods";
import SuggestionList from "../../components/table/userSuggestion";
import ReportList from "../../components/table/userReport";

const Single = () => {
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);
  const [showNav, setShowNav] = useState("comments");

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`users/${userId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [userId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={"/users/edit/" + userId}>Edit</Link>
            </div>
            <h1 className="titlen">User Information</h1>
            {
              <div className="item">
                <img
                  src={uStats.img || require("../../assets/images/staff.png")}
                  alt=""
                  className="itemImg"
                />
                <div className="details">
                  <h1 className="itemTitle">{uStats.name}</h1>
                  <div className="detailItem">
                    <span className="itemKeyu">Email:</span>
                    <span className="itemValue">{uStats.email}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Username:</span>
                    <span className="itemValue">{uStats.username}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Staff:</span>
                    <span className="itemValue">{uStats.isStaff}</span>
                  </div>
                  {/* <div className="detailItem">
                    <span className="itemKeyu">Office:</span>
                    <span className="itemValue">{uStats.office}</span>
                  </div> */}
                  <div className="detailItem">
                    <span className="itemKeyu">Country:</span>
                    <span className="itemValue">Nigeria</span>
                  </div>
                </div>
              </div>
            }
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div> */}
        </div>
        <div className="bottom">
          <div className="btnDiv">
            <button
              onClick={() => setShowNav("comments")}
              className={showNav === "comments" ? "slct" : "idl"}
            >
              Comments
            </button>
            <button onClick={() => setShowNav("reports")}>Reports</button>
            <button
              onClick={() => setShowNav("suggestions")}
              className={showNav === "suggestions" ? "slct" : "idl"}
            >
              Suggestions
            </button>
          </div>
          <h1 className="title">LATEST {showNav.toLocaleUpperCase()}</h1>
          {showNav === "comments" ? (
            <List />
          ) : showNav === "reports" ? (
            <ReportList />
          ) : (
            <SuggestionList />
          )}
        </div>
      </div>
    </div>
  );
};

export default Single;
