import "./singleBudget.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/BudgetTable";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

const SingleBudget = () => {
  const location = useLocation();
  const budgetId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`budget/${budgetId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [budgetId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">Edit</div>
            <h1 className="titlen">Budget</h1>
            {
              <div className="item">
                <img
                  src={uStats.img || require("../../assets/images/staff.png")}
                  alt=""
                  className="itemImgn"
                />
                <div className="details">
                  <h1 className="itemTitle">{uStats.name}</h1>
                  <div className="detailItem">
                    <span className="itemKeyu">M.D.A:</span>
                    <span className="itemValue">{}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Year:</span>
                    <span className="itemValue">{uStats.year}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Programme:</span>
                    <span className="itemValue">{uStats.programme}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Description:</span>
                    <span className="itemValue">{uStats.description}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Economic:</span>
                    <span className="itemValue">{uStats.economic}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Function:</span>
                    <span className="itemValue">{uStats.function}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Location:</span>
                    <span className="itemValue">{uStats.location}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Amount:</span>
                    <span className="itemValue">
                      {uStats.amount &&
                        uStats.amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
          {/* <div className="right">
            <Chart
              aspect={3 / 1}
              title="User Report activity ( Last 6 Months)"
            />
          </div> */}
        </div>
        <div className="bottom">
          <h1 className="title">Last Release</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default SingleBudget;
