import { createSlice } from "@reduxjs/toolkit";

const newsSlice = createSlice({
  name: "news",
  initialState: {
    news: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //Add News
    addNewsStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addNewsSuccess: (state, action) => {
      state.isFetching = true;
      state.news.push(action.payload);
    },
    addNewsFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add Marker
    addMarkerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addMarkerSuccess: (state, action) => {
      state.isFetching = true;
      state.marker.push(action.payload);
    },
    addMarkerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add Project
    addProjectStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addProjectSuccess: (state, action) => {
      state.isFetching = true;
      state.project.push(action.payload);
    },
    addProjectFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add Sector
    addSectorStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addSectorSuccess: (state, action) => {
      state.isFetching = true;
      state.Sector.push(action.payload);
    },
    addSectorFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add Mda
    addMdaStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addMdaSuccess: (state, action) => {
      state.isFetching = true;
      state.Mda.push(action.payload);
    },
    addMdaFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add Lga
    addLgaStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addLgaSuccess: (state, action) => {
      state.isFetching = true;
      state.Lga.push(action.payload);
    },
    addLgaFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add Ward
    addWardStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addWardSuccess: (state, action) => {
      state.isFetching = true;
      state.Ward.push(action.payload);
    },
    addWardFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add FAQ
    addFaqStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addFaqSuccess: (state, action) => {
      state.isFetching = true;
      state.Faq.push(action.payload);
    },
    addFaqFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add FISCAL YEAR
    addFiscalStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addFiscalSuccess: (state, action) => {
      state.isFetching = true;
      state.BudgGrant.push(action.payload);
    },
    addFiscalFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add PERIOD
    addPeriodStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addPeriodSuccess: (state, action) => {
      state.isFetching = true;
      state.GrantFile.push(action.payload);
    },
    addPeriodFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add PARTNER
    addPartnerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addPartnerSuccess: (state, action) => {
      state.isFetching = true;
      state.ProjectFile.push(action.payload);
    },
    addPartnerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  addNewsStart,
  addNewsSuccess,
  addNewsFailure,
  addMarkerStart,
  addMarkerSuccess,
  addMarkerFailure,
  addProjectStart,
  addProjectSuccess,
  addProjectFailure,
  addSectorStart,
  addSectorSuccess,
  addSectorFailure,
  addMdaStart,
  addMdaSuccess,
  addMdaFailure,
  addLgaStart,
  addLgaSuccess,
  addLgaFailure,
  addWardStart,
  addWardSuccess,
  addWardFailure,
  addFaqStart,
  addFaqSuccess,
  addFaqFailure,
  addFiscalStart,
  addFiscalSuccess,
  addFiscalFailure,
  addPeriodStart,
  addPeriodSuccess,
  addPeriodFailure,
  addPartnerStart,
  addPartnerSuccess,
  addPartnerFailure,
} = newsSlice.actions;
export default newsSlice.reducer;
