import "./resetPassword.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect } from "react";
import { userRequest } from "../../requestMethods";

const ResetPassword = ({ title }) => {
  let id =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.admin.id
      : false;

  const [input, setInput] = useState({
    newPassword: "",
    confirm: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  function handleSubmit(e) {
    e.preventDefault();

    setFormErrors(validate(input));
    setIsSubmit(true);
  }

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      isSubmit &&
      input.oldPassword !== "" &&
      input.newPassword !== ""
    ) {
      userRequest
        .post("/admins/resetPassword/" + id, input)
        .then((res) => {
          setInput({
            newPassword: "",
            confirm: "",
          });
          setTimeout(() => {
            setIsSubmit(false);
          }, 10000);
        })
        .catch((error) => {
          // catch any unexpected errors
          //   console.log(error.response.data.msg);
          setFormErrors({ form: error.response.data.msg });
        });
    }
  }, [formErrors, input, isSubmit, id]);

  //   const handleClick = (e) => {
  //     e.preventDefault();
  //     const user = {
  //       ...input,
  //     };
  //     console.log(input);
  //     userRequest
  //       .patch(`admins/changePassword`, user)
  //       .then((res) => {
  //         // setInput({ res });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  const validate = (values) => {
    const errors = {};
    if (!values.newPassword) {
      errors.newPassword = "password is required!";
    }
    if (!values.confirm) {
      errors.confirm = "confirm password is required!";
    }
    if (values.newPassword !== values.confirm) {
      errors.confirm = "confirm password must match new password";
    }
    return errors;
  };

  return (
    <div className="edit">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left"></div>
          <div className="right">
            {Object.keys(formErrors).length === 0 && isSubmit && (
              <div className="ui message success">
                Reset password was successful.
              </div>
            )}
            <i style={{ color: "red" }}>{formErrors.form}</i>
            <form>
              <div className="formInput">
                <label>New Password</label>
                <input
                  type="Password"
                  name="newPassword"
                  value={input.newPassword}
                  className={
                    formErrors.newPassword
                      ? "err"
                      : isSubmit
                      ? "crt"
                      : undefined
                  }
                  placeholder="New Password"
                  onChange={handleChange}
                />
                <i>{formErrors.newPassword}</i>
              </div>
              <div className="formInput">
                <label>Confirm New Password</label>
                <input
                  type="Password"
                  name="confirm"
                  placeholder="Confirm Password"
                  className={
                    formErrors.confirm ? "err" : isSubmit ? "crt" : undefined
                  }
                  value={input.confirm}
                  onChange={handleChange}
                />
                <i>{formErrors.confirm}</i>
              </div>
              <div className="submit-btn">
                <button onClick={handleSubmit}>Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
