import React from "react";
import "./singleMarker.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";

const SingleMarker = () => {
  const location = useLocation();
  const markerId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getMarker = async () => {
      try {
        const res = await userRequest.get(`marker/${markerId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getMarker();
  }, [markerId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonn">Edit</div>
            <h1 className="titlen">Map Markers</h1>
            {
              <div className="item">
                <img
                  src={
                    uStats.img ||
                    require("../../assets/images/default_marker.png")
                  }
                  alt=""
                  className="itemIm"
                />
                <div className="details">
                  {/* <h1 className="itemTitle">{uStats.header}</h1> */}
                  <div className="detailItem">
                    <span className="itemKee">Longitude:</span>
                    <span className="itemValue">{uStats.longitude}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Latitude:</span>
                    <span className="itemValue">{uStats.latitude}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Notification:</span>
                    <span className="itemValue">{uStats.notification}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Information:</span>
                    <span className="itemValue">{uStats.information}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Added Date:</span>
                    <span className="itemValue">{uStats.createdAt}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKee">Added By:</span>
                    <span className="itemValue">{uStats.userId}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMarker;
