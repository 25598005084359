import "./edit.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { userRequest } from "../../requestMethods";
// import {
//   getStorage,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from "firebase/storage";
// import app from "../../firebase";
// import { updateUser } from "../../redux/apiCalls";

const EditUser = ({ title }) => {
  const location = useLocation();
  const docName = location.pathname.split("/")[1];
  const userId = location.pathname.split("/")[3];
  const [file, setFile] = useState(null);
  const [img, setImg] = useState("");
  const [input, setInput] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    mda: "",
    department: "",
  });
  let imageValue;

  // const [cat, setCat] = useState([]);
  //   const dispatch = useDispatch();

  //   const useId = JSON.parse(
  //     JSON.parse(localStorage.getItem("persist:root")).user
  //   ).currentUser.admin.id;

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`${docName}/${userId}`);
        setInput(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [userId, docName]);

  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleCat = async (e) => {
    const imageFile = e.target.files[0];
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("image", imageFile);
    try {
      const {
        data: {
          image: { src },
        },
      } = await userRequest.post(`${docName}/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      imageValue = src;
      setImg(imageValue);
      console.log(imageValue);
    } catch (error) {
      imageValue = null;
      console.log(error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (file !== null) {
      // const fileName = new Date().getTime() + file.name;
      // const storage = getStorage(app);
      // const storageRef = ref(storage, fileName);

      // const uploadTask = uploadBytesResumable(storageRef, file);

      // // Register three observers:
      // // 1. 'state_changed' observer, called any time the state changes
      // // 2. Error observer, called on failure
      // // 3. Completion observer, called on successful completion
      // uploadTask.on(
      //   "state_changed",
      //   (snapshot) => {
      //     // Observe state change events such as progress, pause, and resume
      //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      //     const progress =
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //     console.log("Upload is " + progress + "% done");
      //     switch (snapshot.state) {
      //       case "paused":
      //         console.log("Upload is paused");
      //         break;
      //       case "running":
      //         console.log("Upload is running");
      //         break;
      //       default:
      //         console.log("Uploading");
      //     }
      //   },
      //   (error) => {
      //     // Handle unsuccessful uploads
      //     console.log("unsuccessful upload ");
      //   },
      //   () => {
      //     // Handle successful uploads on complete
      //     // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      //       const user = {
      //         ...input,
      //         img: downloadURL,
      //       };
      //       userRequest
      //         .patch(`admins/${userId}`, user)
      //         .then((res) => {
      //           //   setInput(res);
      //         })
      //         .catch((error) => {
      //           console.log(error);
      //         });

      //       // updateUser(userId, user, dispatch);
      //     });
      //   }
      // );
      const user = {
        ...input,
        img: img,
      };
      userRequest
        .patch(`admins/${userId}`, user)
        .then((res) => {
          //   setInput(res);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const user = {
        ...input,
      };
      console.log(input);
      userRequest
        .patch(`admins/${userId}`, user)
        .then((res) => {
          // setInput({ res });
        })
        .catch((error) => {
          console.log(error);
        });
      //   updateUser(userId, user, dispatch);
    }
  };

  return (
    <div className="edit">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : input.img
                  ? input.img
                  : require("../../assets/images/no-image-icon-0.jpg")
              }
              alt=""
            />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  name="img"
                  onChange={handleCat}
                  style={{ display: "none" }}
                />
              </div>

              <div className="formInput">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  value={input.username}
                  placeholder="username"
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <label>Fullname</label>
                <input
                  type="text"
                  name="name"
                  value={input.name}
                  placeholder="fullname"
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={input.email}
                  placeholder="email address"
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  value={input.phone}
                  placeholder="Phone Number"
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <label>M.D.A</label>
                <input
                  type="text"
                  name="mda"
                  value={input.mda}
                  placeholder="M.D.A"
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <label>Department</label>
                <input
                  type="text"
                  name="department"
                  value={input.department}
                  placeholder="Department"
                  onChange={handleChange}
                />
              </div>
              <div className="formInput">
                <label>Access Right</label>
                {/* <input
                  type="text"
                  name="isStaff"
                  value={input.isStaff}
                  placeholder="staff"
                  onChange={handleChange}
                /> */}
              </div>
              <div className="submit-btn">
                <button onClick={handleClick}>Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
