import "./modal.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethods";

const Modal = ({ closeModal }) => {
  const navigate = useNavigate();
  const [mda, setMda] = useState([]);
  const [text, setText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestion, setSuggestion] = useState([]);
  const [fData, setFData] = useState({
    mda: "",
    month: "",
    year: "",
    pk: "",
  });

  useEffect(() => {
    const loadMda = async () => {
      try {
        const res = await userRequest.get("/mdas");
        // console.log(res.data);
        setMda(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    loadMda();
  }, []);

  const handleSuggestionClick = (suggetion, pk) => {
    setFData((prev) => {
      return { ...prev, pk: pk };
    });
    setText(suggetion);
    setShowSuggestions(false);
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 1) {
      matches = mda.filter((retn) => {
        const regex = new RegExp(`${text}`, "gi");
        return retn.name.match(regex);
        // return retn.name.toLowerCase().includes(text.toLowerCase());
      });
    }
    setSuggestion(matches);
    setText(text);
  };

  const handleChange = (e) => {
    setFData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      ...fData,
      mda: text,
    };
    // closeModal(false);
    console.log(formData);
    navigate("report", { state: formData });
  };
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Budget Monthly Report</h1>
        </div>
        <div className="body">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor="mda">M.D.A:</label>
            <input
              type="text"
              placeholder="M.D.A"
              autoComplete="off"
              value={text}
              onChange={(e) => {
                onChangeHandler(e.target.value);
              }}
              onFocus={() => setShowSuggestions(true)}
            />
            {showSuggestions && (
              <ul className="suggestions">
                {suggestion.slice(0, 10).map((suggest, i) => (
                  <li
                    onClick={() =>
                      handleSuggestionClick(suggest.name, suggest._id)
                    }
                    key={i}
                  >
                    {suggest.name}
                  </li>
                ))}
              </ul>
            )}
            <label htmlFor="month">Month:</label>
            <input
              type="text"
              name="month"
              onChange={handleChange}
              placeholder="Month"
            />
            <label htmlFor="year">Year:</label>
            <input
              type="text"
              name="year"
              onChange={handleChange}
              placeholder="Year"
            />
            <button type="submit" className="modalSubmitBtn">
              Submit
            </button>
          </form>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

export default Modal;
