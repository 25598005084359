import "./singleMda.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import List from "../../components/table/MdaReportTable";

const SingleProject = () => {
  const location = useLocation();
  const mdaId = location.pathname.split("/")[2];
  const [uStats, setUStats] = useState([]);

  useEffect(() => {
    const getMdas = async () => {
      try {
        const res = await userRequest.get(`mdas/${mdaId}`);
        setUStats(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getMdas();
  }, [mdaId]);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButtonu">
              <Link to={"/mdas/edit/" + mdaId}>Edit</Link>
            </div>
            <h1 className="titlen">M.D.A</h1>
            {
              <div className="item">
                {/* <img
                  src={uStats.img || require("../../assets/images/staff.png")}
                  alt=""
                  className="itemImgn"
                /> */}
                <div className="details">
                  <h1 className="itemTitle">{uStats.header}</h1>
                  <div className="detailItem">
                    <span className="itemKeyu">Sector:</span>
                    <span className="itemValue">
                      {uStats.sectorId && uStats.sectorId.name}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Code:</span>
                    <span className="itemValue">{uStats.code}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKeyu">Name</span>
                    <span className="itemValue">{uStats.name}</span>
                  </div>
                  {/* <div className="detailItem">
                    <span className="itemKeyu">Sub Sector:</span>
                    <span className="itemValue"></span>
                  </div> */}
                </div>
              </div>
            }
          </div>
        </div>
        <div className="bottom">
          <List />
        </div>
      </div>
    </div>
  );
};

export default SingleProject;
