import "./modal.scss";
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethods";

const BudgetModal = ({ closeModal }) => {
  const useId = JSON.parse(
    JSON.parse(localStorage.getItem("persist:root")).user
  ).currentUser.admin.id;
  const [file, setFile] = useState(null);
  //   const navigate = useNavigate();
  const [mda, setMda] = useState([]);
  const [text, setText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestion, setSuggestion] = useState([]);
  const [fData, setFData] = useState({
    userId: useId,
    mdaId: "",
    year: "",
  });
  const [csvArray, setCsvArray] = useState([]);

  const processCSV = (str, delim = ",") => {
    // const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const headers = [
      "programme",
      "description",
      "economic",
      "function",
      "location",
      "amount",
    ];
    // console.log(headers);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    var lines = str.slice(str.indexOf("\n") + 1).split("\n").length;
    console.log(lines);

    const newArray = rows.slice(0, lines - 1).map((row) => {
      const values = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
      //   console.log(values);
      const eachObject = headers.reduce(
        (obj, header, i) => {
          obj[header] =
            i !== 5 ? values[i] : parseFloat(values[i].replace(/,|"/g, ""));
          return obj;
        },
        { ...fData }
      );
      return eachObject;
      //   values.map((val, index) => {
      //     let det;
      //     index === 5 ? (det = parseFloat(val.replace(/,|"/g, ""))) : (det = val);
      //     index <= 5 && console.log(det);
      //   });
    });

    setCsvArray(newArray);
    console.log(csvArray);
  };

  useEffect(() => {
    const loadMda = async () => {
      try {
        const res = await userRequest.get("/mdas");
        // console.log(res.data);
        setMda(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (csvArray.length > 0) {
      userRequest.post("/budget", csvArray).then((res) => {
        setFData({
          year: "",
          mda: "",
        });
        setFile(null);
      });
    }
    loadMda();
  }, [csvArray]);

  const handleSuggestionClick = (suggetion, pk) => {
    setFData((prev) => {
      return { ...prev, mdaId: pk };
    });
    setText(suggetion);
    setShowSuggestions(false);
  };

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 1) {
      matches = mda.filter((retn) => {
        const regex = new RegExp(`${text}`, "gi");
        return retn.name.match(regex);
        // return retn.name.toLowerCase().includes(text.toLowerCase());
      });
    }
    setSuggestion(matches);
    setText(text);
  };

  const handleChange = (e) => {
    setFData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const formData = {
  //       ...fData,
  //       mda: text,
  //     };
  //     // closeModal(false);
  //     console.log(formData);
  //     navigate("report", { state: formData });
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      //   console.log(text);
      processCSV(text);
    };

    reader.readAsText(file);
    // const fileName = new Date().getTime() + file.name;
  };
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Add New Budget File</h1>
        </div>
        <div className="body">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor="mda">M.D.A:</label>
            <input
              type="text"
              placeholder="M.D.A"
              autoComplete="off"
              value={text}
              onChange={(e) => {
                onChangeHandler(e.target.value);
              }}
              onFocus={() => setShowSuggestions(true)}
            />
            {showSuggestions && (
              <ul className="suggestions">
                {suggestion.slice(0, 10).map((suggest, i) => (
                  <li
                    onClick={() =>
                      handleSuggestionClick(suggest.name, suggest._id)
                    }
                    key={i}
                  >
                    {suggest.name}
                  </li>
                ))}
              </ul>
            )}
            <label htmlFor="month">Year:</label>
            <input
              type="text"
              name="year"
              onChange={handleChange}
              placeholder="Year"
            />
            <label htmlFor="year">CSV File:</label>
            <input
              type="file"
              id="file"
              accept=".csv"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            <button type="submit" className="modalSubmitBtn">
              Submit
            </button>
          </form>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

export default BudgetModal;
