import "./table.scss";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { userRequest } from "../../requestMethods";
import { useEffect, useState, useRef } from "react";

const List = () => {
  const [tdata, setData] = useState([]);
  const [prev, setPrev] = useState([]);
  const location = useLocation();
  const mdaId = location.pathname.split("/")[2];
  const ref = useRef(null);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`budget/report/${mdaId}/2022`);
        setData(res.data.reverse());
        const res1 = await userRequest.get(
          `budgetPerformance/report/6374384b0b885b2bb07c5c6b`
        );
        // res1.data && console.log(res1.data);
        setPrev(res1.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, [mdaId]);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            {/* <TableCell className="tableCell">Tracking ID</TableCell> */}

            <TableCell className="tableCell">
              Programme Code & Description
            </TableCell>
            <TableCell className="tableCell">Description</TableCell>
            <TableCell className="tableCell">Approved Budget</TableCell>
            <TableCell className="tableCell">Previous Months</TableCell>
            <TableCell className="tableCell">Total to Date</TableCell>
            <TableCell className="tableCell">Performance</TableCell>
            <TableCell className="tableCell">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tdata.map((row) => (
            <TableRow key={row._id}>
              <TableCell className="tableCell">{row.programme}</TableCell>
              <TableCell className="tableCell">{row.description}</TableCell>
              <TableCell className="tableCell">
                {row.amount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </TableCell>
              <TableCell className="tableCell">
                {prev
                  .filter((person) => person._id === row._id)
                  .map((filteredPerson) =>
                    filteredPerson.totalAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
              </TableCell>
              <TableCell className="tableCell">
                {prev
                  .filter((person) => person._id === row._id)
                  .map((filteredPerson) =>
                    filteredPerson.totalAmount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
              </TableCell>
              <TableCell className="tableCell">
                {prev
                  .filter((person) => person._id === row._id)
                  .map(
                    (filteredPerson) =>
                      (
                        (filteredPerson.totalAmount / row.amount) *
                        100
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + "%"
                  )}
              </TableCell>
              <TableCell className={"tableCell"} ref={ref}>
                {prev
                  .filter((person) => person._id === row._id)
                  .map((filteredPerson) =>
                    (row.amount - filteredPerson.totalAmount).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
