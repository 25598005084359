// const jwt = require("jsonwebtoken");
import "./new.scss";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethods";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const NewPublicFile = ({ inputs, title }) => {
  //   const useId = JSON.parse(
  //     JSON.parse(localStorage.getItem("persist:root")).user
  //   ).currentUser.admin.id;
  const location = useLocation();
  const fileId = location.pathname.split("/")[3];
  const [file, setFile] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [img, setImg] = useState("");
  const [formData, setFormData] = useState({});
  const [input, setInput] = useState({
    title: "",
    fileType: "",
  });
  const nav = useNavigate();
  let fileValue;

  useEffect(() => {
    if (submit) {
      try {
        userRequest.post("/budgetFile/files", formData).then((res) => {
          // console.log(res.data);
          setInput({ title: "", fileType: "" });
          nav("/budgetFile");
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [formData, submit, nav]);

  const handleSelect = (e) => {
    const newData = { ...input };
    newData[e.target.name] = e.target.value;
    setInput(newData);
  };

  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleCat = async (e) => {
    const budgetFile = e.target.files[0];
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", budgetFile);
    try {
      const {
        data: {
          file: { src },
        },
      } = await userRequest.post(`budgetFile/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      fileValue = src;
      setImg(fileValue);
      // console.log(fileValue);
    } catch (error) {
      fileValue = null;
      console.log(error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (file !== null) {
      setFormData({
        ...input,
        id: fileId,
        url: img,
      });
      setSubmit(true);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img src={require("../../assets/images/file-icon.png")} alt="" />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  File: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  style={{ display: "none" }}
                  onChange={handleCat}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === "select" ? (
                    <select name="fileType" onChange={handleSelect}>
                      <option value="">{input.field}</option>
                      <option value="0">Citizens Budget</option>
                      <option value="1">State Budget</option>
                      <option value="2">Budget Performance</option>
                      <option value="3">Mtef</option>
                    </select>
                  ) : (
                    <input
                      type={input.type}
                      name={input.field}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ))}
              {/*<div className="formInput">
                <label>M.D.A</label>
                 <input
                  type={input.type}
                  name={input.field}
                  placeholder={input.placeholder}
                  onChange={handleChange}
                /> 
                <select>
                  <option value="636cb4bfaa4fb8abda61cb01">
                    Office of the Deputy Governor
                  </option>
                </select>
              </div>*/}
              <div className="submit-btn">
                <button onClick={handleClick}>Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPublicFile;
