import { userRequest } from "./requestMethods";
export const productColumns = [
  { field: "_id", headerName: "ID", width: 150 },
  {
    field: "product",
    headerName: "Product",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="product" />
          {params.row.title}
        </div>
      );
    },
  },
  {
    field: "desc",
    headerName: "Description",
    width: 250,
  },

  {
    field: "color",
    headerName: "Color",
    width: 200,
  },
  {
    field: "categories",
    headerName: "Categories",
    width: 200,
  },
  {
    field: "size",
    headerName: "Size",
    width: 100,
  },
  {
    field: "color",
    headerName: "Color",
    width: 200,
  },
  {
    field: "inStock",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.inStock}`}>
          {params.row.inStock}
        </div>
      );
    },
  },
];

export const userColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "user",
    headerName: "User",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./assets/images/staff.png")}
            alt="avatar"
          />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 280,
  },

  {
    field: "isStaff",
    headerName: "Staff",
    headerAlign: "center",
    width: 100,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerAlign: "center",
    width: 180,
  },
];

export const lgaColumns = [
  {
    field: "name",
    headerName: "L.G.A",
    headerAlign: "center",
    width: 500,
  },
];

export const wardColumns = [
  {
    field: "lgaId",
    headerName: "L.G.A",
    headerAlign: "center",
    width: 500,
  },
  {
    field: "name",
    headerName: "Ward",
    headerAlign: "center",
    width: 500,
  },
];

export const fileColumns = [
  {
    field: "fiscal",
    headerName: "Year",
    headerAlign: "center",
    width: 300,
  },
  {
    field: "docs",
    headerName: "File(s)",
    headerAlign: "center",
    width: 300,
    renderCell: (params) => {
      return params.row.docs.length;
    },
  },
];

export const projectFileColumns = [
  {
    field: "partner",
    headerName: "Partner",
    headerAlign: "center",
    width: 450,
  },
  {
    field: "partnerType",
    headerName: "Partners Base",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "docs",
    headerName: "File(s)",
    headerAlign: "center",
    width: 300,
    renderCell: (params) => {
      return params.row.docs.length;
    },
  },
];

export const faqColumns = [
  {
    field: "question",
    headerName: "Question",
    headerAlign: "center",
    width: 500,
  },
  {
    field: "answer",
    headerName: "Answer",
    headerAlign: "center",
    width: 500,
  },
];

export const sectorColumns = [
  {
    field: "name",
    headerName: "Sector",
    headerAlign: "center",
    width: 500,
  },
];

export const mdaColumns = [
  {
    field: "sectorId",
    headerName: "Sector",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return params.row.sectorId && params.row.sectorId.name;
    },
  },
  {
    field: "code",
    headerName: "Code",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "name",
    headerName: "M.D.A",
    headerAlign: "center",
    width: 500,
  },
];

export const accountColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "user",
    headerName: "User",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./assets/images/staff.png")}
            alt="avatar"
          />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 300,
  },

  {
    field: "phone",
    headerName: "Phone",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 160,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
];

export const newsColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "news",
    headerName: "Title",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./assets/images/staff.png")}
            alt="avatar"
          />
          {params.row.header}
        </div>
      );
    },
  },
  {
    field: "message",
    headerName: "News",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 330,
  },
  {
    field: "eventDate",
    headerName: "Date",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 120,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 120,
  },
  // {
  //   field: "userId",
  //   headerName: "Added By",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   width: 150,
  //   renderCell: (params) => {
  //     return params.row.userId.name;
  //   },
  // },
];

export const newsletterColumns = [
  {
    field: "_id",
    headerName: "ID",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 170,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 300,
  },
  {
    field: "createdAt",
    headerName: "Date Subscribed",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
];

export const markerColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "notification",
    headerName: "Notification",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={
              params.row.img || require("./assets/images/default_marker.png")
            }
            alt="avatar"
          />
          {params.row.notification}
        </div>
      );
    },
  },
  {
    field: "longitude",
    headerName: "Longitude",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 160,
  },
  {
    field: "latitude",
    headerName: "Latitude",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 160,
  },
  {
    field: "information",
    headerName: "Information",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "userId",
    headerName: "Added By",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Date Added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 120,
  },
];

export const contactColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 180,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 150,
  },
  {
    field: "subject",
    headerName: "Subject",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 150,
  },
  {
    field: "message",
    headerName: "Message",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
  },
  {
    field: "createdAt",
    headerName: "Date Submitted",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 120,
  },
];

export const reportColumns = [
  {
    field: "userId",
    headerName: "User",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return params.row.userId.name;
    },
  },
  {
    field: "projectId",
    headerName: "Project",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 450,
    renderCell: (params) => {
      return params.row.projectId.title;
    },
  },
  {
    field: "status",
    headerName: "Project Status",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "performance",
    headerName: "Performance (%)",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 110,
  },
];

export const suggestionColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  // {
  //   field: "userId",
  //   headerName: "User",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   width: 200,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithImg">{/* {params.row.userId.name} */}</div>
  //     );
  //   },
  // },
  // {
  //   field: "sector",
  //   headerName: "Sector",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   width: 130,
  // },
  {
    field: "lga",
    headerName: "L.G.A",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 150,
    renderCell: (params) => {
      return params.row.lga ? params.row.lga.name : "";
    },
  },
  {
    field: "title",
    headerName: "Title",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 330,
  },
  {
    field: "comment",
    headerName: "Comment",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 450,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 110,
  },
];

export const projectColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "title",
    headerName: "Title",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./assets/images/staff.png")}
            alt="avatar"
          />
          {params.row.title}
        </div>
      );
    },
  },
  {
    field: "sector",
    headerName: "Sector",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "mda",
    headerName: "M.D.A",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 110,
  },
  {
    field: "lga",
    headerName: "L.G.A",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 250,
  },
  {
    field: "ward",
    headerName: "Ward",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 250,
  },
  {
    field: "longitude",
    headerName: "Location",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 250,
  },
];

export const budgetColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "mdaId",
    headerName: "M.D.A",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
    renderCell: (params) => {
      return params.row.mdaId.name;
    },
  },
  {
    field: "programme",
    headerName: "Programme Code & Description",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 320,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 450,
  },
  // {
  //   field: "economic",
  //   headerName: "Economic Code & Description",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   minWidth: 200,
  // },
  // {
  //   field: "function",
  //   headerName: "Function Code & Description",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   width: 200,
  // },
  // {
  //   field: "location",
  //   headerName: "Location Code & Description",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   width: 200,
  // },
  {
    field: "amount",
    headerName: "Approved Budget",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return (
        params.row.amount &&
        params.row.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const budgetPerformanceColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "budgetId",
    headerName: "Budget",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
    renderCell: (params) => {
      return params.row.budgetId.description;
    },
  },
  {
    field: "month",
    headerName: "Month",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 320,
    renderCell: (params) => {
      return months[params.row.month - 1];
    },
  },
  {
    field: "amount",
    headerName: "Amount Release",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return (
        params.row.amount &&
        params.row.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
  },
];

// const getPrev = async (id) => {
//   try {
//     const res = await userRequest.get(`budgetPerformance/report/${id}`);
//     res.data[0] && console.log(res.data[0]);
//     return res.data[0] && JSON.stringify(res.data[0].totalAmount);
//   } catch (error) {
//     console.log(error);
//   }
// };

const getPrev = async (id) => {
  try {
    const res = await userRequest.get(`budgetPerformance/report/${id}`);
    res.data[0] && console.log(res.data);
    return res.data[0] && JSON.stringify(res.data[0]);
  } catch (error) {
    console.log(error);
  }
};

export const returnColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "programme",
    headerName: "Programme Code & Description",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 450,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 450,
  },
  {
    field: "amount",
    headerName: "Approved Budget",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return (
        params.row.amount &&
        params.row.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
  },
  {
    field: "previous",
    headerName: "Previous Months",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 250,
    renderCell: (params) => {
      getPrev(params.row._id);
      return params.row._id;
    },
  },
  {
    field: "current",
    headerName: "Current Month",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 150,
    renderCell: () => {
      return (
        <div>
          <input
            style={{ width: "80%", padding: 5, outline: "none" }}
            type="text"
            name="previous"
          />
        </div>
      );
    },
  },
  {
    field: "total",
    headerName: "Total to Date",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "performance",
    headerName: "Performance",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "balance",
    headerName: "Balance",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 100,
  },
];
